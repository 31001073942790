* {
  box-sizing: border-box;
  font-family: sans-serif !important;
}

html,
body {
  margin: 0 auto;
  padding: 0;
  overscroll-behavior: none;
}

body {
  overscroll-behavior: none;
}

#root {
  overflow-x: hidden;
}